html {
  scroll-behavior: smooth;
}

#policy-content {
  margin: 0 auto;
  width: 80%;
  text-align: start;
  height: 100%;
}
.policyHeading {
  display: block;
  font-family: Mulish;
  /* margin-top: 256px; */
  text-align: start;
  margin-bottom: 35px;
  padding-top: 15px;
  color: #000;
  font-weight: 500;
  line-height: 1.2;
  font-size: 2.5rem;
}

.policySubHeading {
  display: block;
  font-family: Mulish;
  /* margin-top: 256px; */
  text-align: start;
  margin-bottom: 35px;
  padding-top: 15px;
  color: #000;
  font-weight: 500;
  line-height: 1.2;
  font-size: 2rem;
}

.policySubSubHeading {
  display: block;
  font-family: Mulish;
  /* margin-top: 256px; */
  text-align: start;
  margin-bottom: 35px;
  padding-top: 15px;
  color: #000;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem;
}

.policyPara {
  display: flex;
  font-family: Mulish;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #000;
  font-size: large;
  line-height: 1.5em;
  text-align: start;
  width: 100%;
}

.policyLink {
  background-color: transparent;
  font-weight: 400;
  color: #000;
  text-decoration: none;
  font-family: Mulish;
}

.marginTop {
  margin-top: 256px;
}
